<template>
  <div>
    <wd-navbar>
      <div slot="left" class="middle" @click="$goBack">
        <wd-icon name="arrow-left" />
      </div>
      <div>
        <span>我的设置</span>
      </div>
    </wd-navbar>

    <div style="background-color: #f6f6f6">
      <wd-cell class="cellList" style="font-size: 14px;" :is-link="false">
        <div slot="title" style="white-space: nowrap">
          学工号：{{getPhoneNum()}}
        </div>
        <div slot="icon" style="display: flex; align-items: center; margin-right: 20px;">
          <img src="../../assets/img/tx.svg" style="width: 40px" />
        </div>
      </wd-cell>
      <div style="margin-top: 10px">
<!--          <wd-cell class="cellList" style="font-size: 14px;" :is-link="true" to="/bind">-->
<!--            <div slot="title">-->
<!--              切换项目-->
<!--            </div>-->
<!--            <div slot="icon">-->
<!--              <img src="../../assets/img/group.svg" style="width: 20px; margin-right: 10px" alt="" />-->
<!--            </div>-->
<!--          </wd-cell>-->
<!--           -->
      </div>
    </div>

    <wd-tabbar v-model="tabbar" fixed border>
      <wd-tabbar-item icon="wd-icon-bags" class="tabbarClass" to="/home">首页</wd-tabbar-item>
      <wd-tabbar-item icon="wd-icon-setting" class="tabbarClass">设置</wd-tabbar-item>
    </wd-tabbar>
  </div>
</template>

<script>

    export default {
        data(){
            return {
                userInfo: {
                    userid: "",
                    phoneNum: "",
                    roleKey: "",
                },
                tabbar: 1,
            }
        },
        created() {
          this.userInfo.phoneNum  = localStorage.getItem("phoneNum")
          this.userInfo.userid    = localStorage.getItem("appUserId")
          this.userInfo.roleKey   = localStorage.getItem("roleId")
        },
        methods: {
            goto(url){
                this.$router.push(url)
                console.log(url, "啥")
            },
            getPhoneNum(){
                return localStorage.getItem('phoneNum')
            }
            // logout(){
            //     this.$messageBox.confirm('确认退出登录？', '提示').then(() => {
            //         localStorage.removeItem("appUserId")
            //         // window.location.href = Vue.prototype.casServerOutUrl
            //         // localStorage.removeItem("appUserId")
            //         // localStorage.removeItem("phoneNum")
            //         // localStorage.removeItem("roleKey")
            //         // this.$toast.success("退出成功！")
            //         // setTimeout(()=>{
            //         //     this.$router.push('/login')
            //         // }, 500)
            //     }).catch(err=>{
            //
            //     })
            // }
        }
    }
</script>

<style lang="less" scoped>
  .cellList{
    /deep/ .wd-cell__wrapper{
      align-items: center;
      .wd-cell__title{
        flex: 2;
      }
    }
  }
  .tabbarClass{
    /deep/ .wd-tabbar-item__label {
      font-size: 14px;
      font-family: 微软雅黑;
      margin-top: 5px;
    }
  }
</style>
